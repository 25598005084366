// Grayscale
$WHITE: #ffffff;
$BLACK: #000000;
$GRAY2: #222222;
$GRAY3: #333333;
$GRAY6: #666666;
$GRAYA: #aaaaaa;
$GRAYD: #dddddd;
$GRAYE: #eeeeee;

// Chromatic
$RED: #ff3b30;
$ORANGE: #ff9500;
$YELLOW: #ffcc00;
$GREEN: #34c759;
$TEAL: #5ac8fa;
$BLUE: #007aff;
$INDIGO: #5856d6;
$PURPLE: #af52de;
$PINK: #ff2d55;

$PRIMARY: #e54948;
$SECONDARY: #cc3f3f;
$TERITARY: #a83434;
$ERROR: #ff0000;
$DARK: #21252a;
$VALID: #e7f0fe;
