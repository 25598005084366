@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.container {
  display: flex;
  flex-direction: column;
  @include flexbox.flexbox(center, center);
  text-align: center;
  font-size: 13px;

  h1 {
    padding: 15px 0;
    font-size: 25px;
    font-weight: 700;
  }
  h2 {
    padding: 10px 0 45px 0;
    font-size: 15px;
    font-weight: 500;
  }

  .member {
    margin-bottom: 30px;

    .name {
      font-size: 20px;
      font-weight: 700;
      margin-top: 20px;
      margin-bottom: 5px;
    }
    .role {
      font-size: 15px;
    }

    .profileImg {
      width: 145px;
      height: 145px;
      border-radius: 100%;
    }
  }
}

@media (min-width: 768px) {
  .meberList {
    display: flex;
    max-height: 1024px;
    width: 100%;
    justify-content: space-around;
  }
}
