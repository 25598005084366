@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.container {
  display: flex;
  flex-direction: column;
  @include flexbox.flexbox(center, center);
  width: 310px;
  padding: 0 40px;

  .logo {
    width: 250px;
    height: 50px;
  }

  .loginForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;

    .wrapper {
      margin: 5px 0 50px 0;
      min-height: 50px;

      p {
        font-size: 16px;
        color: colors.$GRAY6;
        padding: 0 5px;
      }

      input {
        width: 100%;
        height: 40px;
        padding: 15px;
        margin-top: 5px;
        border: 1px solid colors.$GRAYD;
        border-radius: 5px;

        &:focus {
          border: 1px solid colors.$GRAY2;
        }
      }
      .invalid {
        margin-top: 5px;
        color: colors.$ERROR;
        font-size: 15px;
      }
    }

    .loginBtn {
      color: colors.$WHITE;
    }
  }
}

@media (min-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    @include flexbox.flexbox(center, center);
    width: 540px;
    padding: 0 40px;

    .logo {
      width: 250px;
      height: 50px;
    }

    .loginForm {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 50px;

      .wrapper {
        height: 76px;

        input {
          height: 60px;
          padding: 25px 15px;
        }
      }

      .loginBtn {
        height: 60px;
      }
    }
  }
}
