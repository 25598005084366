@use '/src/styles/constants/colors';
@use '/src/styles/mixins/flexbox';

.container {
  display: flex;
  flex-direction: column;
  @include flexbox.flexbox(center, center);
  width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('https://api.lorem.space/image/burger');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: darken;
  color: colors.$WHITE;

  .heroSection {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    @include flexbox.flexbox(center, center);
    width: 324px;

    h1 {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 35px;
    }

    p {
      font-size: 15px;
      text-align: center;
      word-spacing: 2px;
      line-height: 27px;
    }

    .startBtn {
      font-size: 15px;
      margin-top: 20px;
    }
  }
}

@media (min-width: 768px) {
  .container {
    font-size: 10px;

    main {
      h1 {
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      p {
        text-align: center;
        word-spacing: 2px;
        line-height: 27px;
      }
    }
  }
}
