@use '/src/styles/constants/colors';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 95px;
  padding: 0 50px;
  background-color: colors.$DARK;
  color: colors.$WHITE;

  .logo {
    flex: none;
    width: 260px;
    height: 60px;
  }

  .email {
    display: none;
    margin-left: 5px;
  }

  .loginBtn {
    flex: none;
    min-width: max-content;
    box-sizing: border-box;
    font-size: 20px;
    margin-left: 15px;
    padding: 15px 18px;
    background-color: colors.$SECONDARY;

    &:hover {
      background-color: colors.$TERITARY;
    }
  }
}

@media (min-width: 768px) {
  .container {
    height: 50px;
    font-size: 15px;

    .logo {
      width: 130px;
      height: 30px;
    }

    .email {
      display: inline-block;
    }

    .loginBtn {
      font-size: 12px;
      padding: 10px 15px;
    }
  }
}
