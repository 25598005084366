.container {
  width: 100%;
  height: 100%;

  main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}
