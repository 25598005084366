@use '/src/styles/constants/colors';

.button {
  background-color: colors.$PRIMARY;
  color: colors.$WHITE;
  padding: 15px 17px;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: inherit;

  &:hover {
    background-color: colors.$SECONDARY;
  }
}
